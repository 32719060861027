<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-26 10:50:09
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-05-20 16:37:03
 * @FilePath: /mediatom-web/src/viewsForManage/Flow/AdplaceConfig/LeftMain/AccountCheckBox.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="account-checkbox">
    <div @click="isExpend = !isExpend" class="account">
      <span>
        <a-checkbox @click.stop="clickAccountCheckBox" :checked="checkedAll" :indeterminate="indeterminate" :disabled="disabled"></a-checkbox>
        <span style="margin-left: 8px;" :title="accountInfo.platAccountName">{{ accountInfo.platAccountName }}</span>
      </span>
      <!-- <a-icon :class="{'is-expend': isExpend, 'icon': true}" type="down" /> -->
      <Arrow :class="{'is-expend': isExpend, 'icon': true}"/>
    </div>
    <div class="source-list" v-if="isExpend">
      <SourceCheckBox
        :last="index === sourceList.length - 1"
        :currentCheckedIdList="currentCheckedIdList"
        @checkedSource="checkedSource"
        :checkedIdList="checkedIdList"
        v-for="(source, index) in sourceList"
        :key="source.dspId"
        :sourceInfo="source"/>
    </div>
  </div>
</template>

<script>
import SourceCheckBox from './SourceCheckBox'
import Arrow from '@/assets/icons/arrow.svg?inline'
export default {
  name: 'AccountCheckBox',
  components: { SourceCheckBox, Arrow },
  data () {
    return {
      isExpend: false
    }
  },
  props: {
    accountInfo: {
      default: () => ({}),
      type: Object,
      required: true
    },
    checkedIdList: {
      default: () => ([]),
      type: Array,
      required: true
    },
    currentCheckedIdList: {
      default: () => ([]),
      type: Array,
      required: true
    }
  },
  computed: {
    sourceList () {
      if (!this.accountInfo || !this.accountInfo.childList) {
        return []
      }
      return this.accountInfo.childList || []
    },
    // 禁用
    disabled () {
      const ids = this.sourceList.map((source) => source.dspId)
      return ids.every((id) => this.checkedIdList.includes(id))
    },
    // 全选
    checkedAll () {
      const ids = this.sourceList.map((source) => source.dspId)
      return ids.every((id) => this.currentCheckedIdList.includes(id))
    },
    // 部分选中
    indeterminate () {
      const ids = this.sourceList.map((source) => source.dspId)
      return ids.some((id) => this.currentCheckedIdList.includes(id)) && !ids.every((id) => this.currentCheckedIdList.includes(id))
    }
  },
  methods: {
    checkedSource (dspId) {
      if (this.currentCheckedIdList.includes(dspId)) {
        this.checkedSourceForAccount(this.currentCheckedIdList.filter((id) => id !== dspId))
      } else {
        this.checkedSourceForAccount([...this.currentCheckedIdList, dspId])
      }
    },
    checkedSourceForAccount (idList) {
      this.$emit('checkedSourceForAccount', idList)
    },
    clickAccountCheckBox () {
      if (this.disabled) {
        return
      }
      if (this.checkedAll) {
        // 已全选
        // 过滤currentCheckedIdlist扔出这个账号以外的且不在checkedIdList中的dspId
        // 需要过滤掉的id数组
        const nocheckedIdList = this.sourceList.map((source) => source.dspId).filter((id) => !this.checkedIdList.includes(id))
        // 过滤后的id数组
        const currentCheckedIdList = this.currentCheckedIdList.filter((id) => !nocheckedIdList.includes(id))
        // 抛出
        this.checkedSourceForAccount(currentCheckedIdList)
      } else {
        // 未全选
        // 选中当前账户下所有未选中的广告源
        const accountSourceIdList = this.sourceList.map((source) => source.dspId)
        // 合并已选id数组后去重
        const currentCheckedIdList = [...new Set([...this.currentCheckedIdList, ...accountSourceIdList])]
        // 抛出
        this.checkedSourceForAccount(currentCheckedIdList)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.account-checkbox {
  user-select: none;
  padding-top: 10px;
  padding-bottom: 10px;
  &:last-child{
    border-bottom: none;
  }
  .account{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-content: center;
    color: @defaultFontColor !important;
    .icon{
      // width: 12px;
      // height: 12px;
      transition: transform 0.3s;
      &.is-expend{
        transform: rotate(180deg);
      }
    }
  }
  .source-list{
    padding-top: 10px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
  }
}
</style>
